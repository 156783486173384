import { Component, Input } from '@angular/core';

@Component({
    selector: 'ga-tag-icon',
    templateUrl: './ga-tag-icon.component.html',
    styleUrls: ['./ga-tag-icon.component.scss'],
})
export class GaTagIconComponent {
    @Input() type = 'default';
    @Input() icon = 'info';
    @Input() size = 20;

    get selectIcon() {
        switch (this.icon) {
            case 'upload':
                return 'cloud_upload';
            case 'success':
                return 'check_circle';
            case 'info':
                return 'info';
            case 'warning':
                return 'warning';
            case 'error':
                return 'error_outline';
            case 'notification':
                return 'notifications';
            case 'wifi':
                return 'wifi';
            default:
                return 'help';
        }
    }
}
