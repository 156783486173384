<div
  class="content"
  [ngClass]="'tag-' + type"
  [attr.style]="'width: ' + size * 2.55 + 'px; height: ' + size * 2.55 + 'px;'"
>
  <i
    class="material-icons-outlined"
    [ngClass]="['tag-' + type]"
    [attr.style]="
      'width: ' +
      size * 1.8 +
      'px; height: ' +
      size * 1.8 +
      'px; font-size: ' +
      size +
      'px'
    "
    >{{ selectIcon }}</i
  >
</div>
