import { Component, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
    selector: 'ga-content-dialog',
    templateUrl: './ga-content-dialog.component.html',
    styleUrls: ['./ga-content-dialog.component.scss'],
})
export class GaContentDialogComponent {
    icon: string;
    iconType: string;
    title: string;
    message: string;
    showButtons: boolean;
    btnError: boolean;
    template: TemplateRef<any>;
    confirm: Subject<boolean> = new Subject();
    close: Subject<boolean> = new Subject();
    loading = false;
    showOnlyOne = false;
    closeButtonMessage = 'Fechar';

    constructor(
        public dialogRef: MatDialogRef<GaContentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: GaContentDialogModel
    ) {
        this.icon = data.icon;
        this.iconType = data.iconType;
        this.title = data.title;
        this.message = data.message;
        this.showButtons =
            typeof data.showButtons == 'boolean' ? data.showButtons : true;
        this.btnError = data.btnError || false;
        this.showOnlyOne = data.showOnlyOne || false;
        if (data.closeButtonMessage) this.closeButtonMessage = data.closeButtonMessage;
        this.template = data.template;
    }

    onConfirm(): void {
        this.confirm.next(true);
    }

    onDismiss(): void {
        this.close.next(true);
        this.dialogRef.close(false);
    }
}

export interface GaContentDialogModel {
    icon: string;
    iconType: string;
    title: string;
    message: string;
    showButtons: boolean;
    btnError: boolean;
    btnMessage: string;
    template: TemplateRef<any>;
    showOnlyOne: boolean;
    closeButtonMessage: string;
}
