import { Component, Inject, Injectable } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarRef, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

type Message = {
    msg: string;
    subtitle?: string;
    isFixed?: boolean;
};

@Injectable({ providedIn: 'root' })
export class NewNotificationService {
    snackbarConfig: {
        verticalPosition: MatSnackBarVerticalPosition;
        horizontalPosition: MatSnackBarHorizontalPosition;
    } = {
            verticalPosition: 'top',
            horizontalPosition: 'right',
        };
    constructor(private _snackBar: MatSnackBar) { }

    success({ msg, subtitle, isFixed }: Message) {
        this._snackBar.openFromComponent(ResponseNotificationComponent, {
            ...this.snackbarConfig,
            data: { msg, subtitle, type: 'success', icon: 'check_circle' },
            panelClass: ['success', 'notify'],
            duration: isFixed ? null : 5000
        });
    }

    info({ msg, subtitle, isFixed }: Message) {
        return this._snackBar.openFromComponent(ResponseNotificationComponent, {
            ...this.snackbarConfig,
            data: { msg, subtitle, type: 'info', icon: 'info' },
            panelClass: ['info', 'notify'],
            duration: isFixed ? null : 5000
        });
    }

    error({ msg, subtitle, isFixed }: Message) {
        this._snackBar.openFromComponent(ResponseNotificationComponent, {
            ...this.snackbarConfig,
            data: { msg, subtitle, type: 'error', icon: 'cancel' },
            panelClass: ['error', 'notify'],
            duration: isFixed ? null : 5000
        });
    }


}

@Component({
    selector: 'response-notification',
    styleUrls: ['./new-notification.scss'],
    template: `
        <div class="notify-container">
            <mat-icon class="info">{{data.icon}}</mat-icon>
            <p>
                <span [ngClass]="{'title': data.subtitle}">
                    {{data.msg}}
                </span>
                <br/>
                {{data.subtitle}}
            </p>
            <span class="spacer"></span>
            <mat-icon 
                class="close" 
                (click)="ref.dismiss()"
                style="cursor: pointer"
            >close</mat-icon>
        </div>
    `
})
export class ResponseNotificationComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        public ref: MatSnackBarRef<ResponseNotificationComponent>
    ) { }
}

