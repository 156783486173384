import { defaultColors, TenantTheme } from './models/tenant-theme.model';
import { tenantTable } from "./tenantTable";
import { defaultTheme } from "./themes/demo";

export function themeSetup(tenantName, service) {
    const theme: TenantTheme = tenantTable[tenantName] ?? defaultTheme;
    setTenantColors(theme.colors);
    setChartColors(theme.chart);
    setTenantLogo(theme.logo, service);
    setDefaultColors(defaultColors);

}

export function setTenantColors(obj: TenantTheme['colors']) {
    Object.entries(obj).forEach(([key, value]) => {
        const root = document.documentElement;
        root.style.setProperty(`--${key}`, value);
    });
    window['tenant'] = {
        ...window['tenant'],
        colors: obj
    };
}

export function setChartColors(obj: TenantTheme['chart']) {
    Object.entries(obj.colors).forEach(([key, value]) => {
        const root = document.documentElement;
        root.style.setProperty(`--${key}`, value);
    });
    window['tenant'] = {
        ...window['tenant'],
        chart: obj
    };
}

export function setTenantLogo(logo: any, tenantService) {
    let url = null
    if (logo && typeof logo == 'string' && (logo.startsWith('\n<svg') || logo.startsWith('<svg'))) {
        url = createSvgUrl(logo);
    }
    window['tenant'] = {
        ...window['tenant'],
        logo: url ?? logo
    };
    tenantService.$previewLogo.next(url ?? logo);
}

export function setTenantIco(ico: any, icoEle: HTMLLinkElement) {
    window['tenant'] = {
        ...window['tenant'],
        favicon: ico
    };
    icoEle.setAttribute('href', ico);
}

export function setDefaultColors(obj: any) {
    Object.entries(obj).forEach(([key, value]: [string, string]) => {
        const root = document.documentElement;
        root.style.setProperty(`--${key}`, value);
    });
    window['tenant'] = {
        ...window['tenant'],
        default: obj
    };
}

export function createSvgUrl(asset: any) {
    const blob = new Blob([asset], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    return url;
}

export function createSvgBase64(asset: any) {
    return new Promise((resolve, reject) => {
        const blob = new Blob([asset], { type: 'image/svg+xml' });
        const reader = new FileReader();

        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsDataURL(blob);
    });
}

export function createIcoUrl(asset: any) {
    const blob = new Blob([asset], { type: 'image/x-icon' });
    const url = URL.createObjectURL(blob);
    return url;
}

export function createPngUrl(blob: any) {
    const url = URL.createObjectURL(blob);
    return url;
}

