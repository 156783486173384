import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';

type Message = {
    msg: string;
    severity?: string;
}

@Injectable({ providedIn: 'root' })
export class NotificationService {
    constructor(
        private _snackBar: MatSnackBar,
    ) { }

    acceptTerms() {
        const acceptSnack = this._snackBar.openFromComponent(SnackBarAceiteComponent,
            {
                panelClass: 'accept',
            }
        );

        return acceptSnack;
    }

    show({ msg }: Message) {
        this._snackBar.openFromComponent(SnackBarErroComponent,
            {
                data: { msg },
                horizontalPosition: "center",
                verticalPosition: "bottom",
                duration: 5000
            })
    }

    success({ msg }: Message) {
        this._snackBar.openFromComponent(SnackBarErroComponent,
            {
                data: { msg },
                panelClass: 'success',
                horizontalPosition: "center",
                verticalPosition: "bottom",
                duration: 5000
            })
    }

    error({ msg }: Message) {
        const snackBarRef = this._snackBar.openFromComponent(SnackBarErroComponent,
            {
                data: { msg },
                panelClass: 'error',
                horizontalPosition: "center",
                verticalPosition: "bottom",
                duration: 5000
            })
        snackBarRef.onAction().pipe(take(1)).subscribe(() => {
            snackBarRef.dismiss();
        });
    }

}

@Component({
    selector: 'snackbar-custom',
    host: { class: 'custom' },
    template: `<span>{{data.msg}}</span> <mat-icon class="icon" (click)="ref.dismiss()">close</mat-icon>`
})

export class SnackBarErroComponent implements OnInit {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public ref: MatSnackBarRef<SnackBarErroComponent>) { }

    ngOnInit() { }
}

@Component({
    selector: 'snackbar-accept',
    host: { class: 'accept-custom' },
    template: `
    <span>Nós usamos cookies e outras tecnologias semelhantes para melhorar
        a sua experiência de navegação. Ao utilizar nossos serviços, 
        você concorda com tal monitoramento. Informamos ainda que atualizamos a 
        nossa <a href="https://www.greenant.com.br/politica-de-privacidade" target="_blank">política de privacidade</a>
        e os nossos <a href="https://www.greenant.com.br/termos-de-servico" target="_blank">termos de serviço</a>.
    </span>
    <button (click)="ref.dismissWithAction()">OK</button>
    `
})

export class SnackBarAceiteComponent implements OnInit {
    constructor(public ref: MatSnackBarRef<SnackBarAceiteComponent>) { }

    ngOnInit() { }
}