import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RouterModule } from "@angular/router";
import { AlertAlterationComponent } from './alertAlteration/alertAlteration.component';
import { BaseBreadcrumbComponent } from "./base-breadcrumb/base-breadcrumb.component";
import { BreadbrumDropdownComponent } from './breadcrumb-dropdown/breadcrumb-dropdown.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { ConfirmIdentityComponent } from './confirmIdentity/confirmIdentity.component';
import { ExplanationComponent } from "./explanation/explanation.component";
import { GaContentDialogComponent } from "./ga-content-dialog/ga-content-dialog.component";
import { GaTagIconComponent } from "./ga-tag-icon/ga-tag-icon.component";
import { GaInputMoneyComponent } from "./input-money";
import { GaInputPercentComponent } from "./input-percent";
import { GaInputTraitComponent } from "./input-trait";
import { LoadingIndicatorComponent } from "./loading-indicator/loading-indicator.component";
import { ResponseNotificationComponent } from "./new-notification/new-notification.service";
import { NotificationService, SnackBarErroComponent } from './notification/notification.service';
import { TrackByPropertyDirective } from "./track-by-property.directive";

const exportedComponents = [
  LoadingIndicatorComponent,
  ConfirmComponent,
  ConfirmIdentityComponent,
  AlertAlterationComponent,
  BaseBreadcrumbComponent,
  BreadbrumDropdownComponent,
  ExplanationComponent,
  TrackByPropertyDirective,
  GaInputPercentComponent,
  GaInputMoneyComponent,
  GaInputTraitComponent,
  GaContentDialogComponent,
  GaTagIconComponent
]

@NgModule({
  declarations: [
    exportedComponents,
    SnackBarErroComponent,
    ResponseNotificationComponent,
    TrackByPropertyDirective
  ],
  exports: [...exportedComponents],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    RouterModule
  ],
  providers: [
    NotificationService
  ]
})
export class SharedModule { }
