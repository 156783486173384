import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';

@Component({
    selector: 'ga-input-money',
    template: `
    <mat-form-field class="input" [hideRequiredMarker]="true"
          [floatLabel]="'never'" style="text-align: right;">
          <span class="currencyvalue" matPrefix>{{trait || 'R$'}} &nbsp;</span>
        <input
            matInput
            type="text"
            [name]="nameAcessor"
            placeholder="0,00"
            (keydown.enter)="onTouched(form.value)"
            (blur)="onTouched(form.value)"
            [formControl]="form"
            />
    </mat-form-field>
    `,
    styles: [`
        .input {
        border: 1px solid var(--primary);
        border-radius: 4px;
        margin-left: 10px;
        width: 120px;
        }

        .input.mat-form-field-disabled {
            border-color: var(--subtext);
        }

        .input.mat-form-field-disabled .currencyvalue{
            color: var(--subtext);
        }

        .input ::ng-deep .mat-form-field-underline,
        .input ::ng-deep .mat-form-field-ripple {
            height: 0px !important;
        }

        .input ::ng-deep .mat-form-field-wrapper {
            padding-bottom: 0px !important;
        }

        .input ::ng-deep .mat-form-field-flex {
            align-items: center !important;
        }

        .input ::ng-deep .mat-form-field-prefix {
            padding-left: 10px;
        }

        .input ::ng-deep .mat-form-field-infix {
            border-top: none !important;
            margin-right: 10px;
        }
    `],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GaInputMoneyComponent),
            multi: true
        }
    ]
})

export class GaInputMoneyComponent implements ControlValueAccessor, OnChanges {
    form = new FormControl();
    onTouched;
    @Input() disabledInput: boolean = false;
    @Input() requiredInput: boolean = false;
    @Input() trait: string;
    @Input() nameAcessor: string = 'moneyInput';
    @Input() maxNumberOfDecimals: number = 2;
    @Input() allowNull = false;

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabledInput && changes.disabledInput.currentValue != undefined) {
            this.setDisabledState(changes.disabledInput.currentValue);
        }
        if (changes.requiredInput && changes.requiredInput.currentValue != undefined) {
            this.setRequiredState(changes.requiredInput.currentValue);
        }
    }

    writeValue(val: any): void {
        const value = val ?? undefined
        if (value) {
            let numberOfDecimals = value.toString().split('.')[1]?.length || 2;
            numberOfDecimals = numberOfDecimals > this.maxNumberOfDecimals
                ? this.maxNumberOfDecimals : numberOfDecimals

            const curency = value.toFixed(numberOfDecimals).replace('.', ',');
            this.form.setValue(curency, { onlySelf: true });

        }
        else if (value == 0 && this.form.value) {
            this.form.setValue(0, { onlySelf: true })
        }
    }

    registerOnChange(fn: any): void {
        this.form.valueChanges.pipe(map(value => {
            if (value && !isNaN(parseFloat(value.replace(',', '.')))) {
                return parseFloat(value.replace(',', '.'))
            }
            return this.allowNull ? null : 0;
        })).subscribe(fn)
    }

    registerOnTouched(fn: any): void {
        const customFn = (newValue) => {
            if (typeof newValue == 'string' && !isNaN(parseFloat(newValue?.replace(',', '.')))) {
                if (newValue.includes(',') && newValue.includes('.')) {
                    let parsed = newValue.replace('.', ' ').replace(',', '.');
                    newValue = parseFloat(parsed.replace(' ', ''))
                }
                else {
                    newValue = parseFloat(newValue.replace(',', '.'));
                }
            }
            else if (typeof newValue != 'number') {
                newValue = 0
            }
            this.writeValue(newValue)
            fn(newValue)
        }
        this.onTouched = customFn
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.form.disable() : this.form.enable();
    }

    setRequiredState?(isRequired: boolean): void {
        isRequired ? this.form.addValidators([Validators.required]) : this.form.removeValidators([Validators.required]);
    }
}
